import "./src/styles/global.scss"

import React from 'react';
import { Parallax } from 'react-parallax';
import {ParallaxProvider} from "react-scroll-parallax";

export const wrapRootElement = ({ element, props }) => {
  return (
    <ParallaxProvider>
      {element}
    </ParallaxProvider>
  )
}